import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import WebsiteIcon from '../icons/website.svg'
import TwitterIcon from '../icons/x-icon.svg'
import TelegramIcon from '../icons/telegram.svg'
import UserIcon from '../icons/user.svg'
import ChefIcon from '../icons/chef.svg'
import ChartIcon from '../icons/graph.svg'
import ArrowUpIcon from '../icons/arrow-up.svg'
import ArrowDownIcon from '../icons/arrow-down.svg'
import ThunderIcon from '../icons/thunder.svg'
import StarIcon from '../icons/star.svg'
import StarlineIcon from '../icons/star-line.svg'
import UsersIcon from '../icons/users.svg'
import jonIcon from '../icons/x-icon.svg'
import LaunchpadChangeUp from '../icons/launchpad-change-up.svg'
import LaunchpadChangeDown from '../icons/launchpad-change-down.svg'
import CopyIcon from '../icons/copy.svg'
import ProfileIcon from '../icons/rocket.svg'
import DexIcon from '../icons/trader-joe.webp'
import ChadAbi from '../config/ChadPumpAbi.json'
import { Link } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { toast } from 'react-hot-toast'
import rot13 from '../../utils/encode.ts'
import Web3 from 'web3'
import { getDefaultAddress } from '../utils/addressHelpers.ts'
import { waitForTransactionReceipt, writeContract } from '@wagmi/core'
import { config } from '../config.jsx';
import { useAccount } from 'wagmi'
import ClipLoader from 'react-spinners/ClipLoader'
import formatNumber from '../utils/constants.ts'
import { wagmiAdapter } from '../App.jsx'

const LaunchpadCard = ({
  apiUrl,
  coinNames,
  web3Clients,
  buyAmount,
  startTime,
  chainId,
  progress,
  Liquidity,
  tokenName,
  tokenSymbol,
  tokenDescription,
  Logo,
  Banner,
  chadAddress,
  depositedAmount,
  contractAddress,
  tokenAddress,
  devAddress,
  dexName,
  marketCap,
  website,
  twitter,
  telegram,
  BlockchainLogo,
  onMouseEnter,
  onMouseLeave,
  lpCreated,
  watchlist,
  toggleWatchlist,
  lastAction,
  lastActionNumber,
  top15HoldersPercent,
  devPercent,
  holdersNumber
}) => {
  const cookies = new Cookies();
  const { address } = useAccount()
  const [creating, setCreating] = useState(false);

  const link = `/buy/?chain=${chainId}&address=${chadAddress}`
  const progressText = progress
  if (progress > 100) {
    progress = 100
  }
  if (website && !website?.includes("https")) {
    website = "https://".concat(website)
  }
  if (telegram && !telegram?.includes("https")) {
    telegram = "https://".concat(telegram)
  }
  if (twitter && !twitter?.includes("https")) {
    twitter = "https://".concat(twitter)
  }
  const handleInnerLinkClick = (event) => {
    event.stopPropagation(); // Prevent the outer link from being activated
    const innerHref = event.currentTarget.getAttribute('data-href');
    if (innerHref) {
      window.location.href = innerHref;
    }
  };

  function calculateTimeDuration(timestamp) {
    const now = Date.now();
    const duration = now - timestamp;

    const seconds = Math.floor(duration / 1000);
    if (seconds < 60) {
      return `${seconds}s`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours}h`;
    }

    const days = Math.floor(hours / 24);
    if (days < 7) {
      return `${days}d`;
    }
  }

  let refAddress
  if (cookies.get('ref')) {
    if (Web3.utils.isAddress(rot13(cookies.get('ref')))) {
      refAddress = rot13(cookies.get('ref'))
    }
  } else {
    refAddress = getDefaultAddress()
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const onTokenSwap = async () => {
    try {
      setCreating(true)
      let swap
      let sendData;
      swap = await writeContract(wagmiAdapter.wagmiConfig, {
        address: chadAddress,
        abi: ChadAbi,
        functionName: 'buyToken',
        value: Math.floor(buyAmount * 10 ** 18),
        args: [refAddress, 0],
        account: address,
        gas: 7000000
      })
      // await delay(8000); // 8-second delay
      await waitForTransactionReceipt(wagmiAdapter.wagmiConfig, {
        hash: swap
      })
      sendData = {
        chainId,
        buyer: address,
        type: 'bought',
        name: tokenSymbol,
        amount: buyAmount,
        token: tokenAddress,
        contract: contractAddress,
        timestamp: (Date.now() / 1000).toFixed(0)
      }
      if (sendData) {
        const response = await fetch(apiUrl + '/api/addHistory', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'error',
          body: JSON.stringify(sendData)
        })
        if (response.status !== 200) {
          const { error } = await response.json()
          throw new Error(error)
        }
      }
      setTimeout(function () {
        setCreating(false)
      }, 5000)
      toast.success(`Successfully ${Number(buyAmount).toLocaleString()}   ${coinNames[chainId]} swapped`)
    } catch (err) {
      console.log('>>>>', err)
      if (address === undefined) {
        toast.error('Connect wallet')
      } else {
        toast.error('There is a problem with your Swap. Please try again later')
      }
      setCreating(false)
    }
  }

  const SocialSection = ({ website, telegram, twitter }) => (
    <div
      className="flex justify-center"
    >
      <a className='pr-0.5 sm:pr-1' href={`${twitter}`} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
        <img src={TwitterIcon} alt="Twitter" width={18} />
      </a>
      <a className='px-0.5 sm:px-1' href={`${telegram}`} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
        <img src={TelegramIcon} alt="Telegram" width={18} />
      </a>
      <a className='px-0.5 sm:px-1' href={website} onClick={handleInnerLinkClick} target="_blank" rel="noopener noreferrer">
        <img src={WebsiteIcon} alt="Website" width={18} />
      </a>
    </div>
  )

  return (
    <div className='flex items-center text-[12px] sm:text-[14px] p-2 gap-3 bg-[#12141688] rounded border border-[#929292]' style={{ boxShadow: "0px 0px 5.5px 3px rgba(232, 232, 234, 0.37)" }}>
      {Logo}
      <div className='flex flex-col gap-1 w-full text-[#ccc]'>
        <div className='flex items-center justify-between'>
          <div className='flex flex-col gap-0.5'>
            <Link to={link}>
              <div className='flex items-center gap-4'>
                <div className='font-semibold text-white'>
                  {tokenSymbol}  /  {tokenName}
                </div>
                {BlockchainLogo}
              </div>
            </Link>
            <div className='flex items-center text-[12px] sm:text-[14px]'>
              <div className='border-r border-[#222] pr-1 sm:pr-[0.35rem]'>
                {calculateTimeDuration(startTime * 1000)}
              </div>
              <div className='flex gap-2 items-center border-r border-[#222] px-1 sm:px-[0.35rem]'>
                <img src={UserIcon} width={15} />
                {Math.floor(top15HoldersPercent)}%
              </div>
              <div className='flex gap-2 items-center border-r border-[#222] px-1 sm:px-[0.35rem]'>
                <img src={ChefIcon} width={15} />
                {Math.floor(devPercent)}%
              </div>
              <div className='flex gap-2 items-center px-1 sm:px-[0.35rem]'>
                <img src={ChartIcon} width={15} />
                {Number(progress).toLocaleString()}%
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center'>
            <img src={lastAction === 'bought' ? ArrowUpIcon : ArrowDownIcon} width={20} />
            <div className={`${lastAction === 'bought' ? 'text-green-500' : 'text-red-500'} text-[11px] sm:text-[12px]`}>
              <span>{lastAction === 'bought' ? 'BUY' : 'SELL'}</span>
              <span className='text-[11px] sm:text-[11px]'>[{lastActionNumber}]</span>
            </div>
          </div>
          <button onClick={onTokenSwap} className='flex items-center border border-[#a135bb] rounded px-2 py-1 hover:scale-95 cursor-pointer disabled:opacity-30 disabled:cursor-not-allowed' disabled={buyAmount === undefined || lpCreated}>
            {creating ? <ClipLoader
              color={'#a135bb'}
              loading={creating}
              size={24}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> : <img src={ThunderIcon} width={24} />}
          </button>
        </div>
        <div className='flex justify-between items-center'>
          <SocialSection
            website={website}
            telegram={telegram}
            twitter={twitter}
          />
          <div className='flex items-center text-[12px] sm:text-[14px] text-green-500'>
            <div className='border-r border-[#222] pr-1 sm:pr-2 flex items-center'>
              <button onClick={() => toggleWatchlist(chadAddress)}>
                <img src={watchlist.includes(chadAddress) ? StarIcon : StarlineIcon} width={18} />
              </button>
            </div>
            <div className='flex gap-2 items-center border-r border-[#222] px-1 sm:px-2'>
              <img src={UsersIcon} width={18} />
              {formatNumber(holdersNumber)}
            </div>
            <div className='flex gap-2 items-center border-r border-[#222] px-1 sm:px-2'>
              <span className='text-white'>V</span>
              ${formatNumber(Math.round(depositedAmount))}
            </div>
            <div className='flex gap-2 items-center px-1 sm:px-2'>
              <span className='text-white'>MC</span>
              ${formatNumber(Math.round(marketCap))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

LaunchpadCard.propTypes = {
  progress: PropTypes.number.isRequired,
  Liquidity: PropTypes.number.isRequired,
  tokenName: PropTypes.string.isRequired,
  raisingPercent: PropTypes.number.isRequired,
}

LaunchpadCard.defaultProps = {
  RugProof: false,
  AllIn: false
}

export default LaunchpadCard
